import { useState, useEffect } from "react"
import { FormGroup, Row, Col, Label, Input } from 'reactstrap';
// import { Progress } from "../../../components/Progress/sca";
import { useForm } from "react-hook-form";
import { Button, CustomForm } from "../Form/style"
import '../Form/style.css';
import jsonData from '../../components/json/accountsType.json';
import countriesData from "../../components/json/countriesStates.json";
import { toast } from "react-toastify";
import { clientService, activitiesServices, usersServices } from "../../services";
import TokenService from "../../services/token.service";
import loader from "../../assets/images/loader.gif";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import PasswordValidation from "../Form/passwordValidation";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { PiExclamationMarkDuotone } from "react-icons/pi";
import { Trans, useTranslation } from 'react-i18next';
import CryptoJS from "crypto-js";

import '../../index.css';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const decryptData = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
};

export const MoroccoForm = ({ entity }) => {
    const [refValue, setRefValue] = useState('');
    const [ptrValue, setPtrValue] = useState('');
    const [filteredAccountTypes, setFilteredAccountTypes] = useState('');
    const [token, setToken] = useState('');
    const [userLogin, setUserLogin] = useState('');
    const [checkEmail, setCheckEmail] = useState(false);
    const [formattedDate, setFormattedDate] = useState('');
    const [currentStep, setCurrentStep] = useState(1);
    const [countryid, setCountryid] = useState(0);
    const [stateid, setStateid] = useState(0);
    //const [declaration, setDeclaration] = useState(false);
    const [agreementId, setAgreementId] = useState('');
    const [check, setCheck] = useState(false);
    const [emailError, setEmailError] = useState(0);
    const [imageList, setImageList] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [countryList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [rec, setRec] = useState({});
    const [nationality, setNationality] = useState(''); // Define nationality state
    const [tin, setTin] = useState();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isCheck, setIsCheck] = useState(false);
    const [fileData, setFileData] = useState('');
    const [correctInfo, setCorrectInfo] = useState(false);
    const [checkBox, setCheckBox] = useState(false);
    const [tick, setTick] = useState('');
    const [entityValue, setEntityValue] = useState(false);
    const [platForm, setPlatForm] = useState('MT4');
    const [utmMediumValue, setUtmMediumValue] = useState('');
    const [morocco, setMorocco] = useState(false);
    const [singleClick, setSingleClick] = useState(false);
    const [ipInfo, setIpInfo] = useState(null);
    const [salesUsers, setSalesUsers] = useState([]);
    const [agentId, setAgentId] = useState(null);
    const [email, setEmail] = useState('');
    const [debouncedEmail, setDebouncedEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState(false);

    const { t, i18n } = useTranslation();

    const {
        setValue,
        reset,
    } = useForm();

    // useEffect(() => {
    //     setFilteredAccountTypes(filterAccountTypes('MT4'));
    // }, [refValue]);

    useEffect(() => {
        const fetchIPInfo = async () => {
            try {
                const response = await fetch('https://ipinfo.io/json?token=52a7645817b83a');
                const data = await response.json();
                setIpInfo(data);
            } catch (error) {
                console.error('Error fetching IP information:', error);
            }
        };

        fetchIPInfo();
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const refParam = searchParams.get('rm');
        const ptrParam = searchParams.get('ptr');
        let ibUrl = window.location.origin + `/?ptr=${ptrParam}&rm=${refParam}`;

        if (ibUrl === `${window.location.origin}/?ptr=2000095497&rm=69`) {
            window.location.href = `https://signup-ma.godocm.com/?rm=101&utm_medium=godoweb_ma`;
        }


    }, []);

    useEffect(() => {

        const searchParams = new URLSearchParams(window.location.search);
        const refParam = searchParams.get('rm');
        const ptrParam = searchParams.get('ptr');
        const entityParam = searchParams.get('entity');
        const platform = searchParams.get('platform');
        const utm = searchParams.get('utm_medium');
        const agreementParam = searchParams.get('agr');

        if (entity === 'FSC') {
            const removeCountry = ['AFGHANISTAN', 'CUBA', 'GHANA', 'IRAN', 'LIBYA', 'MYANMAR', 'PANAMA', 'SOUNTH SUDAN', 'SYRIA', 'USA', 'YEMEN'];
            const filterCountry = countriesData.filter((country) => !removeCountry.includes(country.name));
            setCountriesList(filterCountry);
        } else if (entity === 'SCA') {
            const filterCountry = countriesData.filter((country) => country.name === "UNITED ARAB EMIRATES");
            setCountriesList(filterCountry);
        } else {
            setCountriesList(countriesData);
        }

        setFilteredAccountTypes(filterAccountTypes('MT4'));
        // Parse the query parameters from the URL
        // Get the value of the 'ref' parameter

        if (entityParam) {
            setCurrentStep(1);
            formData.step1.country = '';
            formData.step2.idType = '';
            formData.step1.accountType = '';
            formData.step2.modeOfPayment = '';
        }

        // mobile app url
        if (platform === 'MT5' && utm === 'godotrader_app' && entityParam === 'FSA' && (ipInfo !== null && ipInfo.country === 'MA')) {
            window.location.href = `https://signup-ma.godocm.com/?entity=FSA_MA&utm_medium=godotrader_app&platform=MT5`;
        }

        if (platform === 'MT5' && utm === 'godotrader_app' && entityParam === 'FSA_MA') {
            setPlatForm('MT5');
            setUtmMediumValue(utm);
            setMorocco(true);
            formData.step1.platform = 'MT5';
            formData.step1.utmMedium = utm;
            setFilteredAccountTypes(filterAccountTypes('MT5'));
            // const filterCountry = countriesData.filter((country) => country.name === "MOROCCO");
            // setCountriesList(filterCountry);
        }

        if (platform === 'MT5' && utm === 'godotrader_app' && entityParam === 'FSA') {
            setPlatForm('MT5');
            setUtmMediumValue(utm);
            formData.step1.platform = 'MT5';
            formData.step1.utmMedium = utm;
            setFilteredAccountTypes(filterAccountTypes('MT5'));
        }

        //desktop url
        if ((utm === 'godoweb_ma' && !platform)) {
            setUtmMediumValue(utm);
            setMorocco(true);
            formData.step1.utmMedium = utm;
            // const filterCountry = countriesData.filter((country) => country.name === "MOROCCO");
            // setCountriesList(filterCountry);
            setPlatForm('MT5');
            formData.step1.platform = 'MT5';
            setFilteredAccountTypes(filterAccountTypes('MT5'));
        }

        if (platform === 'MT5' && utm === 'godoweb_ma' && entityParam === 'FSA_MA') {
            setPlatForm('MT5');
            setUtmMediumValue(utm);
            setMorocco(true);
            formData.step1.platform = 'MT5';
            formData.step1.utmMedium = utm;
            setFilteredAccountTypes(filterAccountTypes('MT5'));
            // const filterCountry = countriesData.filter((country) => country.name === "MOROCCO");
            // setCountriesList(filterCountry);
        }

        // Set the value to the state
        if (refParam) {
            setRefValue(refParam);
        }


        if (agreementParam) {
            setAgreementId(agreementParam);
        }

        if (ptrParam) {
            setPtrValue(ptrParam);
        }
        if (ptrParam && entityParam !== 'FSA_MA' && !utm) {
            setEntityValue(true)
            setPtrValue(ptrParam);
        }

        clientService.authorization().then((res) => {
            const token = res.token;
            const decryptToken = decryptData(token);
            TokenService.setUser(decryptToken);
        }).catch((err) => {
            console.log('Error for auth is ', err);
            toast.error("Authorization error!");
        });
    }, [entity, morocco, utmMediumValue, ipInfo]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedEmail(email);  // Set the debounced email after delay
        }, 500);

        return () => {
            clearTimeout(handler);  // Clear the timeout if the user keeps typing
        };
    }, [email]);

    // Call the API when the debounced email changes (after typing stops)
    useEffect(() => {
        const checkEmailExistence = async () => {
            if (debouncedEmail) {
                try {
                    const res = await clientService.emailExist(debouncedEmail);
                    if (res.agentId) {
                        setErrorMessage(true);
                        console.log('work')
                    } else {
                        setErrorMessage(false);
                    }
                } catch (error) {
                    console.error('Error checking email:', error);
                }
            }
        };

        checkEmailExistence();
    }, [debouncedEmail]);

    const [formData, setFormData] = useState({
        step1: { agentId: refValue, parentId: ptrValue, entity: entityValue ? 'FSA' : entity, callStatus: 'New Lead', firstName: '', email: '', phone: '', accountType: '', accountTypeCurrency: '', password: '', confirmPassword: '', country: '', countryCode: '', platform: 'MT4', utmMedium: '' },
        // step2: { nationality: '', state: '', proofOfAddress: '', idType: '', passportIdNo: '', idIssuedPlace: '', dob: '', proofOfIdentity: '', residency: '', resState: '', resIdType: '', resIdNumber: '', resIdIssuePlace: '', resProofOfAddress: '', annualIncome: '', netWorth: '', modeOfPayment: '', sourceOfFunds: '', source_Of_Income: '', taxResidency: '', tin: '', ubo: Boolean, uboState: '', additional: '' },
        step2: { nationality: '', nationalityCode: '', state: '', address: '', proofOfAddress: '', idType: '', idTypeCode: '', passportIdNo: '', year: '', month: '', day: '', proofOfIdentity: '', proofOfIdentityBack: '', residency: '', residencyCode: '', resProofOfAddress: '', annualIncome: '', netWorth: '', modeOfPayment: '', modeOfPaymentCode: '', sourceOfFunds: '', sourceOfFundsCode: '', sourceOfIncome: '', taxResidency: '', taxIdentificationNumber: '', additional: '' },
        step3: { experiencedTrading: false, sharesFrequency: '', cfdFrequency: '', powerOfAttorney: '', learn: true, accountTradedByOthers: false, acceptTradingTerms: true },
        step4: { usCitizen: false, politicallyExposed: false, beneficialOwnerCode: '', declaration: Boolean }
        // Add more steps as needed
    });

    const data = {
        agentId: refValue || '101',
        parentId: ptrValue,
        entity: entityValue ? 'FSA_MA' : entity,
        firstName: formData.step1.firstName,
        // middleName: formData.step1.middleName,
        // lastName: formData.step1.lastName,
        agreementId: agreementId,
        email: formData.step1.email,
        platform: formData.step1.platform,
        accountType: formData.step1.accountType,
        accountTypeCurrency: formData.step1.accountTypeCurrency,
        country: formData.step1.country,
        countryCode: formData.step1.countryCode,
        phone: formData.step1.phone,
        //mobile: formData.step1.phone,
        password: formData.step1.password,
        utmMedium: utmMediumValue,


        nationality: formData.step2.nationality,
        nationalityCode: formData.step2.nationalityCode,
        //state: formData.step2.state,
        dob: formattedDate,
        //proofOfAddress: formData.step2.proofOfAddress,
        idType: formData.step2.idType,
        idTypeCode: formData.step2.idTypeCode,
        passportIdNo: formData.step2.passportIdNo,
        // idIssuedPlace: formData.step2.isIdIssuedPlace || nationality,
        proofOfIdentity: formData.step2.proofOfIdentity,
        proofOfIdentityBack: formData.step2.proofOfIdentityBack,
        residency: formData.step2.residency,
        residencyCode: formData.step2.residencyCode,
        //resState: formData.step2.resState,
        //resIdType: formData.step2.resIdType,
        //resIdNumber: formData.step2.resIdNumber,
        // resIdIssuePlace: formData.step2.resIdIssuePlace,
        resProofOfAddress: formData.step2.resProofOfAddress,
        annualIncome: formData.step2.annualIncome,
        netWorth: formData.step2.netWorth,
        modeOfPayment: formData.step2.modeOfPayment,
        modeOfPaymentCode: formData.step2.modeOfPaymentCode,
        sourceOfFunds: formData.step2.sourceOfFunds,
        sourceOfFundsCode: formData.step2.sourceOfFundsCode,
        sourceOfIncome: formData.step2.sourceOfIncome,
        taxResidency: formData.step2.taxResidency,
        taxIdentificationNumber: formData.step2.taxIdentificationNumber,
        //sameAsAbove: formData.step2.sameAsAbove,
        address: formData.step2.address,
        // ubo: formData.step2.ubo,
        // uboState: formData.step2.uboState,
        additional: formData.step2.additional,

        experiencedTrading: formData.step3.experiencedTrading,
        sharesFrequency: formData.step3.sharesFrequency,
        cfdFrequency: formData.step3.cfdFrequency,
        learn: formData.step3.learn,
        accountTradedByOthers: formData.step3.accountTradedByOthers,
        //powerOfAttorney: formData.step3.powerOfAttorney,
        acceptTradingTerms: formData.step3.acceptTradingTerms,

        usCitizen: formData.step4.usCitizen,
        politicallyExposed: formData.step4.politicallyExposed,
        beneficialOwnerCode: formData.step4.beneficialOwnerCode || 'N',
        declaration: true,

        accountStatus: 'New',
        isLead: false,
        isClient: true,
    }

    const [validationErrors, setValidationErrors] = useState({
        step1: {
            firstName: false, phone: false, accountType: false, otpValue: false, country: false, countryCode: false, password: false, confirmPassword: false, platform: false
        },
        // step2: {
        //     nationality: false, state: false, proofOfAddress: false, idType: false, passportIdNo: false, idIssuedPlace: false, dob: false, proofOfIdentity: false, residency: false, resState: false, resIdType: false, resIdNumber: false, resIdIssuePlace: false, resProofOfAddress: false, annualIncome: false, netWorth: false, modeOfPayment: false, sourceOfFunds: false, source_Of_Income: false, taxResidency: false, taxIdentificationNumber: false, ubo: false, uboState: false, additional: false
        // },
        step2: {
            nationality: false, nationalityCode: false, address: false, proofOfAddress: false, idType: false, idTypeCode: false, passportIdNo: false, dob: false, day: false, month: false, year: false, proofOfIdentity: false, proofOfIdentityBack: false, residency: false, residencyCode: false, resProofOfAddress: false, annualIncome: false, netWorth: false, modeOfPayment: false, modeOfPaymentCode: false, sourceOfFunds: false, sourceOfFundsCode: false, source_Of_Income: false, taxResidency: false, taxIdentificationNumber: false, additional: false
        },
        step3: {
            experiencedTrading: false, sharesFrequency: false, cfdFrequency: false, learn: false, accountTradedByOthers: false, powerOfAttorney: false, acceptTradingTerms: true
        },
        step4: { usCitizen: true, politicallyExposed: true, beneficialOwnerCode: false, declaration: false }
        // other steps...
    });

    const formValueChange = (step, name, value) => {
        // Regular expression for English letters and basic punctuation
        const englishRegex = /^[a-zA-Z0-9\s.,'?!@#$%^&:*()_+=-]*$/;
        const uploadedFileNames = ['proofOfIdentity', 'proofOfIdentityBack', 'resProofOfAddress', 'sourceOfIncome', 'additional', 'powerOfAttorney'];

        if (name === 'email') {
            setEmail(value);
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(value)) {
                setEmailError(1); // Set email error state if the email is invalid
            } else {
                setEmailError(0); // Clear email error state if the email is valid
            }
        }

        if (name === 'phone') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [step]: {
                    ...prevFormData[step],
                    [name]: value.replace(/[^\d]/g, ''),
                },
            }));
        } else if (name === 'dob') {
            console.log('bodData: ', value)
            setFormData((prevFormData) => ({
                ...prevFormData,
                [step]: {
                    ...prevFormData[step],
                    [name]: value,
                },
            }));
        } else if (englishRegex.test(value)) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [step]: {
                    ...prevFormData[step],
                    [name]: value,
                },
            }));
        } else if (uploadedFileNames.includes(name)) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [step]: {
                    ...prevFormData[step],
                    [name]: value,
                },
            }));
        }

        console.log("Account Type", formData.step1);
        //console.log("formdataStep2", formData.step2);
        console.log("phone: ", formData.step1.phone);
        console.log("fileData: ", fileData && fileData);
    };

    const validatePass = (password) => {
        //const regex = /^(?=.*[A-Z])(?=.*[@%&])[A-Za-z\d@#&]{8}$/;
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#&$]).{8,12}$/;
        return regex.test(password);
    }

    const validatePassword = (password) => {
        // Your password validation logic here
        // Example: At least 1 lowercase letter, 1 uppercase letter, 1 non-letter, 8-15 characters, 1 special character
        //const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@%&]).{8,8}$/;
        const isValid = validatePass(password);
        if (isValid) {
            return true;
        }
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step1: {
                ...prevErrors.step1,
                password: !isValid,
            },
        }));
    };


    const combineValidation = () => {
        if (!validatePassword(formData.step1.password)) {
            toast.error("Password must be upper, lower and symbols($,&,@,#)");
            return false;
        }
        if (!(formData.step1.password === formData.step1.confirmPassword)) {
            toast.error("Password and confirm password must match");
            return false;
        }
        const { firstName, email, phone, accountType, password, confirmPassword, country } = formData.step1;
        const { address, proofOfIdentity, proofOfIdentityBack, residency, residencyCode, resProofOfAddress, source_Of_Income, additional } = formData.step2;

        // Check each field individually and set their validity
        const isfirstNameValid = firstName.trim() !== '';
        const isEmailValid = email.trim() !== '';
        const isphoneValid = phone.trim() !== '';
        const isAccountTypeValid = accountType !== '';
        const isPasswordValid = password.trim() !== '';
        const isConfirmPasswordValid = confirmPassword.trim() !== '' && password.trim() === confirmPassword.trim();
        const isCountryValid = country !== '';
        // const isNationalityValid = formData.step2.nationality !== '';
        // const isNationality = nationality !== '';
        // const isNationalityCode = nationalityCode !== '';
        const isAddress = address !== '';
        const isResidency = residency !== '';
        const isResidencyCode = residencyCode !== '';
        let testResult = true;
        // Check if any uploaded files are the same
        const uploadedFiles = [proofOfIdentity, proofOfIdentityBack, resProofOfAddress, source_Of_Income, additional];

        if (proofOfIdentity !== '') {
            for (let i = 0; i < uploadedFiles.length; i++) {
                for (let j = i + 1; j < uploadedFiles.length; j++) {
                    if (uploadedFiles[i] && uploadedFiles[i] === uploadedFiles[j]) {
                        toast.error(`Uploaded files could not be the same`);
                        return testResult = false;
                    }
                }
            }
        }

        // Set the validation errors dynamically for each field
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step1: {
                ...prevErrors.step1,
                firstName: !isfirstNameValid,
                email: !isEmailValid,
                phone: !isphoneValid,
                accountType: !isAccountTypeValid,
                password: !isPasswordValid,
                confirmPassword: !isConfirmPasswordValid,
                country: !isCountryValid,
            },
        }));




        // Return true if all fields are valid, false otherwise
        const validTrue = isfirstNameValid && isEmailValid && isphoneValid && isAccountTypeValid && isPasswordValid && isConfirmPasswordValid && isCountryValid
            && isAddress && testResult && isResidency && isResidencyCode;

        if (!validTrue) {
            toast.error("Please fill all the fields!");
            return false;
        } else {
            return true;
        }
    };

    const submitForm = async (e) => {
        e.preventDefault();

        if (errorMessage) {
            toast.error('Email Exist!');
        }

        if (combineValidation() && !errorMessage) {
            setLoading(true);
            data.acceptedTerms = true;
            data.acceptedRisk = true;
            data.acceptedPrivacy = true;
            data.acceptedExecutionPolicy = true;
            data.acceptedConflicts = true;
            data.phone = phoneNumber !== undefined ? phoneNumber + data.phone.slice(1) : data.phone;
            // console.log("KLdjkfldsf ", data); return;
            const postClient = await clientService.singleRegistration(data);

            if (postClient && !postClient.data && postClient.length !== 0) {

                const formDataValue = new FormData();
                formDataValue.append('customerId', postClient.customerId);
                imageList.forEach((item) => {
                    formDataValue.append(item.key, item.value[0]); // Assuming each item contains only one file
                });

                const logData = {
                    type: 'userRegisterMorocco',
                    customerId: postClient && postClient.customerId,
                    note: { 'note': ipInfo && JSON.stringify(ipInfo) }
                };

                activitiesServices.postActivity(logData);

                if (imageList.length !== 0) {
                    await clientService.uploadDocuments(formDataValue)
                }

                await clientService.authorizationCp(postClient.login.toString(), formData.step1.password).then((res) => {
                    // Once authorization is successful, get the token
                    const token = res.token;
                    setLoading(false);

                    //resetForm();
                    // Construct the redirect URL with the token as a query parameter
                    const redirectURL = `https://login-ma.godocm.com/silent-login?_token=${encodeURIComponent(token)}`;

                    // Redirect the user to the constructed URL
                    window.location.href = redirectURL;
                });
                toast.success('Successfully Registered!');

            } else {
                console.log('error: ', postClient)
                setLoading(false);
                toast.error(postClient.data.message);
            }


        }
    };

    const updateListFunction = (key, files) => {

        // Check if the key already exists in the imageList
        const existingIndex = imageList.findIndex(entry => entry.key === key);

        if (existingIndex !== -1) {
            // If the key already exists, update its value
            const updatedList = [...imageList];
            updatedList[existingIndex].value = files;

            setImageList(updatedList);
            console.log('file: ', imageList)
        } else {
            // If the key does not exist, add it as a new entry
            const newValue = {
                key,
                value: files
            };

            const updatedList = [...imageList, newValue];
            setImageList(updatedList);
            console.log('file: ', imageList)
        }
    };
    const filterAccountTypes = (platform) => {
        console.log("ref value", refValue);

        if (ptrValue && refValue !== 38) {
            return jsonData.filter(item => (item['TP platform'] === platform && item['ib'] === 3))
        } else if (refValue === 38) {
            return jsonData.filter(item => (item['TP platform'] === platform && item['Server Number'] === 2))
        } else {
            return jsonData.filter(item => (item['TP platform'] === platform && item['Server Number'] === 3))
        }
    };

    const accountTypeValue = (platform) => {
        // Determine accountType based on platform
        const accountType = platform === 'MT5' ? '42' : '38';

        // Update form data
        setFormData((prevFormData) => ({
            ...prevFormData,
            step1: {
                ...prevFormData.step1,
                accountType,
                accountTypeCurrency: 'USD'
            },
        }));

        console.log("Account Type Setting", formData.step1);
    }


    return (
        <div style={{ overflow: "hidden", fontSize: i18n.language === 'ar' || i18n.language === 'fe' ? '18px' : '' }}>
            <div id="recaptcha-container" style={{ display: 'none' }}></div>
            {loading ?
                <div id="global-loader1">
                    <img src={loader} className="loader-img" alt="Loading...." />
                </div> : <>
                    {/* <div className="flex-center py-10 RSPBprogressBars d-sms-none">
                        <Progress currentStep={currentStep} morocco={morocco} />
                    </div> */}
                    <CustomForm>
                        {currentStep === 1 &&
                            <div className="justify mt-5">
                                {/* <InfoText /> */}
                                <Row className={`${(i18n.language === 'ar' || i18n.language === 'fe') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={4} style={{ display: 'none' }}>
                                        <FormGroup>
                                            <Label>Ibid</Label>
                                            <div className="input-group">
                                                <Input className={"form-control"}
                                                    name="agentId" type="text"
                                                    required="" placeholder="John"
                                                    value={refValue}
                                                    onChange={(e) => formValueChange('step1', 'agentId', refValue)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4} style={{ display: 'none' }}>
                                        <FormGroup>
                                            <Label>Entity</Label>
                                            <div className="input-group">
                                                <Input className={"form-control"}
                                                    name="entity" type="text"
                                                    required="" placeholder="John"
                                                    value={entity}
                                                    onChange={(e) => formValueChange('step1', 'entity', entity)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>{t('FullName')}</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + ((!validationErrors.step1.firstName || formData.step1.firstName) ? 'is-valid' : 'is-invalid')}
                                                    name="firstName" type="text"
                                                    required="" placeholder="John"
                                                    value={formData.step1.firstName}
                                                    onChange={(e) => {
                                                        // Remove non-alphanumeric characters except spaces
                                                        let cleanedInput = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');

                                                        // Split the input into words, capitalize the first letter of each word, and join them back
                                                        let capitalizedInput = cleanedInput.split(' ')
                                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                            .join(' ');

                                                        // Update the form value
                                                        formValueChange('step1', 'firstName', capitalizedInput);
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col className="mobileView" md={12}>
                                        <Label style={{ color: 'gray' }}> *Please enter your name as per your passport or national ID</Label>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>{t('EmailAddress')}</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + (!validationErrors.step1.email || formData.step1.email ? 'is-valid' : 'is-invalid')}
                                                    type="email"
                                                    name="email"
                                                    disabled={checkEmail ? true : false}
                                                    validation={{ required: true }}
                                                    value={formData.step1.email}
                                                    invalid={emailError === 1 && formData.step1.email !== '' && 1 || validationErrors.step1.email}
                                                    valid={emailError === 0 && formData.step1.email !== '' && 1 || !validationErrors.step1.email}
                                                    placeholder="john@gmail.com"
                                                    onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                />
                                            </div>
                                            {errorMessage && <span className="text-danger" >Email Already Exist</span>}
                                        </FormGroup>
                                    </Col>
                                    {/* {!morocco && <Col md={4}>
                                        <Label>Nationality</Label>
                                        <Input
                                            className={"mb-3 " + (!validationErrors.step2.nationality || formData.step2.nationality ? 'is-valid' : 'is-invalid')}
                                            type="select"
                                            name="nationality"
                                            onChange={(e) => {
                                                const selectedCountryName = e.target.value; // Get the selected country name
                                                const selectedCountry = countriesData.find(country => country.name === selectedCountryName); // Find the country object based on the name
                                                if (selectedCountry) {
                                                    console.log("lksadjfkl country ", selectedCountry.name);
                                                    //setCountryid(selectedCountryName);
                                                    //setSelectedCountryCode(selectedCountry.iso2.toLowerCase());
                                                    formValueChange('step2', 'nationality', selectedCountry.name);
                                                    formValueChange('step2', 'nationalityCode', selectedCountry.iso2);
                                                    // Reset the selected state and city
                                                    // setStateid('');
                                                    // setCityList([]);
                                                    // formValueChange('step2', 'idIssuedPlace', selectedCountry.name); // Update form data with the selected country name
                                                    //setNationality(selectedCountry.name); // Set nationality
                                                    // GetState(selectedCountry.id).then((result) => {
                                                    //     setStateList(result); // Update the state list based on the selected country
                                                    // });
                                                }
                                            }}
                                            value={formData.step2.nationality} // Set the value to the selected country name
                                        >
                                            <option value="">-- Select --</option>
                                            {countriesData.map((item, index) => (
                                                <option key={index} value={item.name}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>} */}
                                    <Col className="DesktopView" md={12}>
                                        <Label style={{ color: 'gray' }}>{t('EnterNameNote')}</Label>
                                    </Col>
                                    <Col md={4} style={{ display: 'none' }}>
                                        <FormGroup>
                                            <Label>Middle Name</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + ((!validationErrors.step1.middleName || formData.step1.middleName) ? 'is-valid' : 'is-invalid')}
                                                    name="middleName" type="text"
                                                    required="" placeholder="Reo"
                                                    value={formData.step1.middleName}
                                                    onChange={(e) => formValueChange('step1', 'middleName', e.target.value)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4} style={{ display: 'none' }}>
                                        <FormGroup>
                                            <Label>Last Name</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + ((!validationErrors.step1.lastName || formData.step1.lastName) ? 'is-valid' : 'is-invalid')}
                                                    type="text"
                                                    name="lastName"
                                                    value={formData.step1.lastName}
                                                    required="" placeholder="Doe"
                                                    onChange={(e) => formValueChange('step1', 'lastName', e.target.value)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className={`${(i18n.language === 'ar' || i18n.language === 'fe') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={12}>
                                        <Row className={`${(i18n.language === 'ar' || i18n.language === 'fe') ? 'flex-row-reverse text-end' : ''}`}>
                                            <Col md={8}>
                                                <Row className={`${(i18n.language === 'ar' || i18n.language === 'fe') ? 'flex-row-reverse text-end' : ''}`}>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label>{t('Platform')}</Label>
                                                            <div className="input-group">
                                                                <Input
                                                                    type="select"
                                                                    name="platform"
                                                                    className={formData.step1.platform ? "is-valid" : "is-invalid"}
                                                                    value={formData.step1.platform}
                                                                    onChange={(e) => {
                                                                        formValueChange('step1', e.target.name, e.target.value);
                                                                        setFilteredAccountTypes(filterAccountTypes(e.target.value));
                                                                        accountTypeValue(e.target.value);
                                                                    }}
                                                                >
                                                                    {platForm === 'MT4' && <option value="MT4">MT4</option>}
                                                                    <option value="MT5">MT5</option>
                                                                    {morocco && formData.step1.utmMedium === 'godoweb_ma' && <option value="MT4">MT4</option>}
                                                                </Input>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label>{t('AccountType')}</Label>
                                                            <div className="input-group">
                                                                <Input
                                                                    type="select"
                                                                    name="accountType"
                                                                    className={!validationErrors.step1.accountType || formData.step1.accountType ? 'is-valid' : 'is-invalid'}
                                                                    value={formData.step1.accountType}
                                                                    onChange={(e) => {
                                                                        if (e.target.value !== '') {
                                                                            formValueChange('step1', e.target.name, e.target.value);
                                                                            const accountCurrency = filteredAccountTypes.filter((account) => account.Id === parseInt(e.target.value));
                                                                            formValueChange('step1', 'accountTypeCurrency', accountCurrency[0].Currency);
                                                                        }
                                                                    }}
                                                                >
                                                                    <option value="">-- Select --</option>
                                                                    {filteredAccountTypes && filteredAccountTypes.map((item, index) => (
                                                                        <option key={index} value={item['Id']}>
                                                                            {item['Account type']}
                                                                        </option>
                                                                    ))}
                                                                </Input>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6} className="position-relative">
                                                        <FormGroup>
                                                            <Label>{t('Password')}</Label>
                                                            <Input
                                                                type={`${showPassword ? "text" : "password"}`}
                                                                name="password"
                                                                className={"form-control required input_password " + (validationErrors.step1.password ? 'is-invalid' : 'is-valid')}
                                                                placeholder="***********"
                                                                minLength={8}
                                                                maxLength={12}
                                                                value={formData.step1.password}
                                                                onChange={(e) => {
                                                                    const { name, value } = e.target;
                                                                    if (value.length < 13) {
                                                                        formValueChange('step1', name, value);
                                                                    }
                                                                }}
                                                            />
                                                            {showPassword ? <FaRegEye onClick={() => setShowPassword(false)} className="position-absolute" style={{ top: "43px", right: "49px" }} /> : <FaRegEyeSlash onClick={() => setShowPassword(true)} className="position-absolute" style={{ top: "43px", right: "49px" }} />}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6} className="position-relative">
                                                        <FormGroup>
                                                            <Label>{t('ConfirmPassword')}</Label>
                                                            <Input
                                                                type={`${showPassword ? "text" : "password"}`}
                                                                name="confirmPassword"
                                                                className={"form-control required input_password " + (validationErrors.step1.confirmPassword ? 'is-invalid' : 'is-valid')}
                                                                placeholder="***********"
                                                                minLength={8}
                                                                maxLength={12}
                                                                value={formData.step1.confirmPassword}
                                                                onChange={(e) => {
                                                                    const { name, value } = e.target;
                                                                    if (value.length < 13) {
                                                                        formValueChange('step1', name, value);
                                                                    }
                                                                }}
                                                            />
                                                            {showPassword ? <FaRegEye onClick={() => setShowPassword(false)} className="position-absolute" style={{ top: "43px", right: "49px" }} /> : <FaRegEyeSlash onClick={() => setShowPassword(true)} className="position-absolute" style={{ top: "43px", right: "49px" }} />}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label>{t('CountryOfResidence')}</Label>
                                                            <Input
                                                                className={"mb-3 " + (!validationErrors.step1.country || formData.step1.country ? 'is-valid' : 'is-invalid')}
                                                                type="select"
                                                                name="country"
                                                                onChange={(e) => {
                                                                    const selectedCountryName = e.target.value;
                                                                    const selectedCountry = countriesData.find(country => country.name === selectedCountryName);
                                                                    if (selectedCountry) {
                                                                        setCountryid(selectedCountry.name);
                                                                        setStateList(selectedCountry.states);
                                                                        //setMainCountry(selectedCountry.id);
                                                                        setSelectedCountryCode(selectedCountry.iso2.toLowerCase());
                                                                        formValueChange('step1', 'country', selectedCountry.name);
                                                                        formValueChange('step1', 'countryCode', selectedCountry.iso2);
                                                                        formValueChange('step2', 'residency', selectedCountry.name);
                                                                        formValueChange('step2', 'residencyCode', selectedCountry.iso2);
                                                                        setNationality(selectedCountry.name); // Set nationality
                                                                        // GetState(selectedCountry.id).then((result) => {
                                                                        //     setStateList(result);
                                                                        //     setStateid('');
                                                                        //     setCityList([]);
                                                                        // });
                                                                    }
                                                                }}
                                                                value={formData.step1.country}
                                                            >
                                                                <option value="">-- Select --</option>
                                                                {countryList.map((item, index) => (
                                                                    <option key={index} value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6} className="phoneNum">
                                                        <Label>{t('PhoneNumber')}</Label>
                                                        <IntlTelInput
                                                            name="phone"
                                                            containerClassName="intl-tel-input"
                                                            inputClassName={"form-control " + (check && !formData.step1.phone && "is-invalid")}
                                                            style={{ width: '100%' }}
                                                            maxLength={15}
                                                            format="true"
                                                            autoFormat={false}
                                                            separateDialCode={false}
                                                            disabled={checkEmail ? true : false}
                                                            defaultCountry={selectedCountryCode || 'ae'}
                                                            useMobileFullscreenDropdown={false}
                                                            value={formData.step1.phone}
                                                            // onSelectFlag={(selectedCountryData, fullNumber, isvalid, currentNumber) => {
                                                            //     setCountryid(fullNumber && fullNumber.iso2 && fullNumber.iso2.toUpperCase());
                                                            // }}
                                                            onPhoneNumberChange={(status, value, countryData, number, isValidNumberPrecise, id, fullNumber) => {
                                                                // let formattedPhoneNumber = "+" + countryData.dialCode + value.slice(1).replace(/\s+/g, '');
                                                                // const isValid = isValidNumberPrecise;
                                                                // console.log('dialData: ', countryData);
                                                                // if (!isValid) {
                                                                //     // Phone number is invalid
                                                                //     console.log('Phone number is invalid');
                                                                //     // You can also set an error state or display an error message here
                                                                //   }
                                                                if (value.length < 15) {
                                                                    setPhoneNumber(countryData.dialCode);
                                                                    formValueChange('step1', 'phone', value);
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <PasswordValidation password={formData.step1.password} />
                                                </FormGroup>
                                            </Col>
                                            {/* {!morocco && <Col md={12}>
                                                {!correctInfo ?
                                                    <div className={`input-group mb-3 mt-4 text-primary`}>
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                name="correctInfo"
                                                                checked={Boolean(checkBox)}
                                                                onChange={(e) => {
                                                                    setCheckBox(!checkBox)
                                                                }}
                                                            />
                                                            &nbsp;&nbsp;&nbsp; The provided information is correct, and I authorize GoDo to use the information to assess the level of appropriateness of the provided services and products.
                                                        </label>
                                                    </div>
                                                    :
                                                    <div className={`input-group mb-3 mt-4 ${checkBox === true ? 'text-primary' : 'text-danger'}`}>
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                name="correctInfo"
                                                                checked={Boolean(checkBox)}
                                                                onChange={(e) => {
                                                                    setCheckBox(!checkBox)
                                                                }}
                                                            />
                                                            &nbsp;&nbsp;&nbsp; The provided information is correct, and I authorize GoDo to use the information to assess the level of appropriateness of the provided services and products.
                                                        </label>
                                                    </div>
                                                }
                                            </Col>} */}
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        }
                        {currentStep === 1 &&
                            <div className="justify w_100" style={{ marginTop: "10px" }}>
                                <Row className={`${(i18n.language === 'ar' || i18n.language === 'fe') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={12} className="mt-3"><h5>{t('PersonalInformation')}</h5></Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>{t('CountryOfResidence')}</Label>
                                            <Input
                                                className={"mb-3 " + (check && !formData.step2.residency && 'is-invalid')}
                                                type="text"
                                                name="residency"
                                                disabled // Disable the input
                                                value={formData.step2.residency || formData.step1.country}
                                            >
                                                <option value="">-- Select --</option>
                                                {countryList.map((item, index) => (
                                                    <option key={index} value={item.name}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <Label>{t('CurrentAddress')}</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"form-control " + ((!validationErrors.step2.address || formData.step2.address) ? 'is-valid' : 'is-invalid')}
                                                name="address" type="text"
                                                value={formData.step2.address}
                                                required=""
                                                onChange={(e) => formValueChange('step2', e.target.name, e.target.value)}
                                                placeholder="House No - Street - City - State" />
                                        </div>
                                    </Col>
                                    <Col md={4} style={{ display: 'none' }}>
                                        <Label for="proofOfAddress">
                                            Upload Document (Proof Of Address)
                                            <div className="tooltip" style={{ opacity: '1', position: 'relative', top: '-3px' }}><PiExclamationMarkDuotone style={{ rotate: '180deg' }} k />
                                                <span className="tooltiptext">Please provide at least ONE of the following documents that
                                                    MUST state <b>current or permanent residential address as per Application form</b> and have been issued within the last 3 calendar months
                                                    <ul>
                                                        <li>Utility Bills or statements from public utilities, including electricity, water, gas, or telephone line providers  </li>
                                                        <li>Lease / Tenancy Agreement  (MUST be within the lease term) </li>
                                                        <li>Local and national government-issued documents, including municipal tax records or similar</li>
                                                        <li>Registered property purchase, lease or rental agreements</li>
                                                        <li>Documents from supervised third-party financial institutions, such as bank statements or insurance policies, alike.</li>
                                                        <li>Incase the client is sharing a bedspace or on a short term rent, may provide permenant residence document of his/her nationality.</li>
                                                    </ul>
                                                </span>
                                            </div>
                                        </Label>
                                        <Input
                                            className={"mb-3 " + (check && !formData.step2.proofOfAddress && "is-invalid")}
                                            type="file"
                                            name="proofOfAddress"
                                            accept=".jpg, .jpeg, .png, .pdf"
                                            onChange={(e) => {
                                                setFileData(e.target.value);
                                                updateListFunction('PROOF_OF_ADDRESS', [e.target.files[0]]);
                                                formValueChange('step2', e.target.name, e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {/* {!morocco && <Row>
                                    <Col md={4}>
                                        <Label>ID Type</Label>
                                        {formData.step2.residency !== 'UNITED ARAB EMIRATES' ?
                                            <div className="input-group mb-3">
                                                <select
                                                    className={"form-control " + (check && !formData.step2.idType && 'is-invalid')}
                                                    name="idType"
                                                    value={formData.step2.idType}
                                                    required=""
                                                    onChange={
                                                        (e) => {
                                                            if (e.target.value !== '') {
                                                                formValueChange('step2', e.target.name, e.target.value)
                                                                if (e.target.value === 'EID-EMIRATES ID') {
                                                                    formValueChange('step2', 'idTypeCode', 'EID')
                                                                } else if (e.target.value === 'PP-PASSPORT') {
                                                                    formValueChange('step2', 'idTypeCode', 'PP')
                                                                } else if (e.target.value === 'NID-NATIONAL ID') {
                                                                    formValueChange('step2', 'idTypeCode', 'NID')
                                                                } else if (e.target.value === 'DL-DRIVING LICENSE') {
                                                                    formValueChange('step2', 'idTypeCode', 'DL')
                                                                } else if (e.target.value === 'RESIDENT PERMIT CARD') {
                                                                    formValueChange('step2', 'idTypeCode', 'RPC')
                                                                }
                                                            }
                                                        }}>
                                                    <option value="">-- Select ID Type --</option>
                                                    <option value="EID-EMIRATES ID">EID-EMIRATES ID</option>
                                                    <option value="PP-PASSPORT">PP-PASSPORT</option>
                                                    <option value="NID-NATIONAL ID">NID-NATIONAL ID</option>
                                                    <option value="DL-DRIVING LICENSE">DL-DRIVING LICENSE</option>
                                                    <option value="RESIDENT PERMIT CARD">RESIDENT PERMIT CARD</option>
                                                </select>
                                            </div> :
                                            <div className="input-group mb-3">
                                                <select
                                                    className={"form-control " + (check && !formData.step2.idType && 'is-invalid')}
                                                    name="idType"
                                                    value={formData.step2.idType}
                                                    required=""
                                                    onChange={(e) => {
                                                        if (e.target.value !== '') {
                                                            formValueChange('step2', e.target.name, e.target.value);
                                                            formValueChange('step2', 'idTypeCode', 'EID')
                                                        }
                                                    }}>
                                                    <option value="">-- Select ID Type --</option>
                                                    <option value="EID-EMIRATES ID">EID-EMIRATES ID</option>
                                                </select>
                                            </div>
                                        }
                                    </Col>
                                    <Col md={4}>
                                        <Label>ID Number</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"form-control " + (check && !formData.step2.passportIdNo && 'is-invalid')}
                                                name="passportIdNo" type="text"
                                                value={formData.step2.passportIdNo}
                                                required=""
                                                onChange={(e) => formValueChange('step2', e.target.name, e.target.value)}
                                                placeholder="ID Number" />
                                        </div>
                                    </Col>
                                    <Col md={4} style={{ display: "none" }}>
                                        <Label>ID Issued Place</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"form-control "}
                                                // className={"form-control " + (check && !formData.step2.idIssuedPlace && 'is-invalid')}
                                                name="idIssuedPlace"
                                                type="text"
                                                value={nationality} // Set the value to nationality
                                                required=""
                                                disabled // Disable the input
                                                placeholder="UAE" />
                                        </div>
                                    </Col>
                                </Row>} */}
                                <Row className={`${(i18n.language === 'ar' || i18n.language === 'fe') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={4}>
                                        <Label for="proofOfIdentity" className="text-primary fw-bold"> {t('ProofOfIdentity')}
                                            <div className="tooltip" style={{ opacity: '1', position: 'relative', top: '-3px' }}><PiExclamationMarkDuotone style={{ rotate: '180deg' }} />
                                                <span className="tooltiptext">Please provide at least <b>ONE</b> of the following documents that
                                                    MUST have legal name, Not expired, date of birth, nationality, & registered ID number:
                                                    <ul>
                                                        <li>Passport - For any Nationality </li>
                                                        <li>Emirates ID, in case the client is UAE Resident </li>
                                                        <li>Any other Government Issued ID which has these credentials</li>
                                                    </ul>
                                                </span>
                                            </div>
                                        </Label>
                                        <Input
                                            className={"mb-1 " + (!morocco && check && !formData.step2.proofOfIdentity && "is-invalid")}
                                            type="file"
                                            name="proofOfIdentity"
                                            id="proofOfIdentity"
                                            accept=".jpg, .jpeg, .png, .pdf, .heic"
                                            onChange={(e) => {
                                                const maxFileSize = 5 * 1024 * 1024; // 2 MB in bytes

                                                const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                                const fileName = e.target.value;
                                                const file = e.target.files[0];
                                                const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                                if (file) {
                                                    if (file.size > maxFileSize) {
                                                        alert('File size exceeds the limit of 5 MB.');
                                                        e.target.value = '';
                                                        formValueChange('step3', e.target.name, e.target.value);
                                                    } else if (fileExtension.includes(fileExtensionCheck)) {
                                                        setFileData(e.target.value);
                                                        updateListFunction('PROOF_OF_ID', [e.target.files[0]]);
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    } else {
                                                        alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                        e.target.value = '';
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    }
                                                }
                                            }}
                                        />
                                        {formData.step2.proofOfIdentity && <span className="fs-6">SelectedFile: {formData.step2.proofOfIdentity.slice(12, 24)}</span>}
                                    </Col>
                                    <Col md={4}>
                                        <Label for="proofOfIdentityBack" className="text-primary fw-bold">{t('ProofOfIdentityBack')}
                                        </Label>
                                        <Input
                                            className={"mb-1 "}
                                            type="file"
                                            name="proofOfIdentityBack"
                                            id="proofOfIdentityBack"
                                            accept=".jpg, .jpeg, .png, .pdf, .heic"
                                            onChange={(e) => {
                                                const maxFileSize = 5 * 1024 * 1024; // 2 MB in bytes

                                                const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                                const fileName = e.target.value;
                                                const file = e.target.files[0];
                                                const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                                if (file) {
                                                    if (file.size > maxFileSize) {
                                                        alert('File size exceeds the limit of 5 MB.');
                                                        e.target.value = '';
                                                        formValueChange('step3', e.target.name, e.target.value);
                                                    } else if (fileExtension.includes(fileExtensionCheck)) {
                                                        setFileData(e.target.value);
                                                        updateListFunction('PROOF_OF_ID_BACK', [e.target.files[0]]);
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    } else {
                                                        alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                        e.target.value = '';
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    }
                                                }
                                            }}
                                        />
                                        {formData.step2.proofOfIdentityBack && <span className="fs-6">SelectedFile: {formData.step2.proofOfIdentityBack.slice(12, 24)}</span>}
                                    </Col>
                                    <Col md={4}>
                                        <Label className="text-primary fw-bold"> {t('ProofOfAddress')}
                                            <div className="tooltip" style={{ opacity: '1', zIndex: '5', position: 'relative', top: '-3px' }}><PiExclamationMarkDuotone style={{ rotate: '180deg' }} />
                                                <span className="tooltiptextRight">Please provide at least ONE of the following documents that
                                                    MUST state <b>current or permanent residential address as per Application form</b> and have been issued within the last 3 calendar months
                                                    <ul>
                                                        <li>Utility Bills or statements from public utilities, including electricity, water, gas, or telephone line providers  </li>
                                                        <li>Lease / Tenancy Agreement  (MUST be within the lease term) </li>
                                                        <li>Local and national government-issued documents, including municipal tax records or similar</li>
                                                        <li>Registered property purchase, lease or rental agreements</li>
                                                        <li>Documents from supervised third-party financial institutions, such as bank statements or insurance policies, alike.</li>
                                                        <li>Incase the client is sharing a bedspace or on a short term rent, may provide permenant residence document of his/her nationality.</li>
                                                    </ul>
                                                </span>
                                            </div>
                                        </Label>
                                        <div className="input-group mb-1">
                                            <Input
                                                className={"form-control"}
                                                name="resProofOfAddress"
                                                type="file"
                                                accept=".jpg, .jpeg, .png, .pdf, .heic"
                                                onChange={(e) => {

                                                    const maxFileSize = 5 * 1024 * 1024; // 2 MB in bytes

                                                    const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                                    const fileName = e.target.value;
                                                    const file = e.target.files[0];
                                                    const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                                    if (file) {
                                                        if (file.size > maxFileSize) {
                                                            alert('File size exceeds the limit of 5 MB.');
                                                            e.target.value = '';
                                                            formValueChange('step3', e.target.name, e.target.value);
                                                        } else if (fileExtension.includes(fileExtensionCheck)) {
                                                            setFileData(e.target.value);
                                                            updateListFunction('RES_PROOF_OF_ADDRESS', [e.target.files[0]]);
                                                            formValueChange('step2', e.target.name, e.target.value);
                                                        } else {
                                                            alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                            e.target.value = '';
                                                            formValueChange('step2', e.target.name, e.target.value);
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                        {formData.step2.resProofOfAddress && <span className="fs-6">SelectedFile: {formData.step2.resProofOfAddress.slice(12, 24)}</span>}
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col md={12} className="mt-4">
                                        <strong>Date of Birth ( as per the given ID )</strong>
                                    </Col>
                                    <Col md={4}>
                                        <Label>Year</Label>
                                        <Input
                                            className={"mb-3 " + ((!validationErrors.step2.year || formData.step2.year) ? 'is-valid' : 'is-invalid')}
                                            name="year"
                                            type="select"
                                            value={formData.step2.year}
                                            onChange={(e) => {
                                                if (e.target.value !== '') {
                                                    formValueChange('step2', e.target.name, e.target.value)
                                                }
                                            }}
                                        >
                                            <option value="">Select Year</option>
                                            {Object.keys(yearData).reverse().map((key) => (
                                                <option key={key} value={yearData[key].year}>{yearData[key].year}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                    <Col md={4}>
                                        <Label>Month</Label>
                                        <Input
                                            className={"mb-3 " + ((!validationErrors.step2.month || formData.step2.month) ? 'is-valid' : 'is-invalid')}
                                            name="month"
                                            type="select"
                                            value={formData.step2.month}
                                            onChange={(e) => {
                                                if (e.target.value !== '') {
                                                    formValueChange('step2', e.target.name, e.target.value)
                                                }
                                            }}
                                        >
                                            <option value="">Select Month</option>
                                            {Object.keys(monthsData).map((key) => (
                                                <option key={key} value={key}>{monthsData[key].name}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                    <Col md={4}>
                                        <Label>Day</Label>
                                        <Input
                                            className={"mb-3 " + ((!validationErrors.step2.day || formData.step2.day) ? 'is-valid' : 'is-invalid')}
                                            name="day"
                                            type="select"
                                            value={formData.step2.day}
                                            onChange={(e) => {
                                                if (e.target.value !== '') {
                                                    formValueChange('step2', e.target.name, e.target.value)
                                                }
                                            }}
                                        >
                                            <option value="">Select Day</option>
                                            {formData.step2.month && [...Array(monthsData[formData.step2.month].days).keys()].map((day) => (
                                                <option key={day + 1} value={day + 1}>
                                                    {String(day + 1).padStart(2, '0')}
                                                </option>
                                            ))}


                                        </Input>
                                    </Col>
                                </Row> */}
                                <Row>
                                    <Col md={12} style={{ display: "none" }} className="mt-3 flex-wrap align-items-center gap-3">
                                        <h5>Resident Status</h5>
                                        <FormGroup check style={{ paddingBottom: '0.1rem' }}>
                                            <Label check>
                                                <Input
                                                    type="checkbox"
                                                    checked={formData.step2.sameAsAbove}
                                                    onChange={() => {
                                                        const newValue = !formData.step2.sameAsAbove;
                                                        formValueChange('step2', 'sameAsAbove', newValue);
                                                        if (newValue) {
                                                            setIsCheck(true);

                                                            // if (formData.step2.nationality !== '') {
                                                            //     formValueChange('step2', 'residency', formData.step2.nationality);
                                                            // }

                                                            formValueChange('step2', 'residency', formData.step2.nationality);// Copy nationality to residency
                                                            formValueChange('step2', 'resState', formData.step2.state);
                                                            formValueChange('step2', 'resIdType', formData.step2.idType);
                                                            formValueChange('step2', 'resIdNumber', formData.step2.passportIdNo);

                                                            if (Array.isArray(imageList[0].value)) {
                                                                updateListFunction('RES_PROOF_OF_ADDRESS', [imageList[0].value[0]]);
                                                            }

                                                            // formValueChange('step2', 'resIdIssuePlace', formData.step2.nationality);
                                                            //formValueChange('step2', 'resProofOfAddress', formData.step2.proofOfAddress);
                                                            // GetState(countryList.find(country => country.name === formData.step2.nationality)?.id).then((result) => {
                                                            //     setResidencyStateList(result); // Update the separate state list based on the selected nationality
                                                            // });
                                                        }
                                                    }}
                                                />
                                                {' '} Same as above
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row style={{ display: "none" }}>
                                    <Col md={12} className="mt-3"><h5>Tax Information</h5></Col>
                                    <Col md={4}>
                                        <Label>Tax Residency:</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"form-control " + (tin ? 'is-valid' : 'is-invalid')}
                                                type="select"
                                                name="taxResidency"
                                                value={formData.step2.taxResidency}
                                                required=""
                                                onChange={(e) => formValueChange('step2', e.target.name, e.target.value)}
                                            >
                                                <option value="">-- Select Country --</option>
                                                {countriesData.map((country, index) => (
                                                    <option key={index} value={country.name}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </Input>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <Label>TIN:</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"form-control " + (check && !formData.step2.taxIdentificationNumber && 'is-invalid')}
                                                name="taxIdentificationNumber"
                                                type="text" // Keep this as text to handle the custom validation
                                                value={formData.step2.taxIdentificationNumber}
                                                required=""
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    // Ensure only numeric values are set
                                                    if (/^\d*$/.test(value)) {
                                                        formValueChange('step2', e.target.name, value);
                                                    }
                                                }}
                                                placeholder="TIN"
                                            />
                                        </div>
                                    </Col>


                                </Row>
                                {/* {!morocco && <Row>
                                    <Col xl={4}>
                                        <Label for="additional" className="w-100 d-flex align-items-center mt-3 text-primary fw-bold    "><h5>Supplementary Document (If any)</h5>
                                            <div className="tooltip" style={{ opacity: '1', position: 'relative', top: '-3px' }}><PiExclamationMarkDuotone style={{ rotate: '180deg' }} />
                                                <span className="tooltiptext">Any Other Supporting Document</span></div>
                                        </Label>
                                        <Input
                                            className={"mb-1 optionalInput "}
                                            // className={"mb-3 " + (check && !formData.step2.additional && "is-invalid")}
                                            type="file"
                                            name="additional"
                                            accept=".jpg, .jpeg, .png, .pdf, .heic"
                                            onChange={(e) => {

                                                const maxFileSize = 5 * 1024 * 1024; // 2 MB in bytes

                                                const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                                const fileName = e.target.value;
                                                const file = e.target.files[0];
                                                const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                                if (file) {
                                                    if (file.size > maxFileSize) {
                                                        alert('File size exceeds the limit of 5 MB.');
                                                        e.target.value = '';
                                                        formValueChange('step3', e.target.name, e.target.value);
                                                    } else if (fileExtension.includes(fileExtensionCheck)) {
                                                        setFileData(e.target.value);
                                                        updateListFunction('ADDITIONAL', [e.target.files[0]]);
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    } else {
                                                        alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                        e.target.value = '';
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    }
                                                }
                                            }}
                                        />
                                        {formData.step2.additional && <span className="fs-6">SelectedFile: {formData.step2.additional.slice(12, 24)}</span>}
                                    </Col>
                                </Row>} */}
                            </div>
                        }
                        {currentStep === 2 &&
                            <div className="justify w_100 mt-4">
                                <Row>
                                    <Col md={4}>
                                        <Label>Have you experienced trading before?</Label>
                                        <div className="input-group mb-3">
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="experiencedTrading"
                                                    id="experiencedTradingYes"
                                                    value="Yes"
                                                    checked={formData.step3.experiencedTrading === true}
                                                    onChange={(e) => formValueChange('step3', e.target.name, true)} />
                                                <Label className="form-check-label" htmlFor="experiencedTradingYes">Yes</Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="experiencedTrading"
                                                    id="experiencedTradingNo"
                                                    value="No"
                                                    checked={formData.step3.experiencedTrading === false}
                                                    onChange={(e) => formValueChange('step3', e.target.name, false)} />
                                                <Label className="form-check-label" htmlFor="experiencedTradingNo">No</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    {formData.step3.experiencedTrading === true && (
                                        <>
                                            <Col md={4}>
                                                <Label>What products you have traded in?</Label>
                                                <div className="input-group mb-3">
                                                    <Input
                                                        className={"form-control " + ((!validationErrors.step3.sharesFrequency || formData.step3.sharesFrequency) ? 'is-valid' : 'is-invalid')}
                                                        type="select"
                                                        name="sharesFrequency"
                                                        value={formData.step3.sharesFrequency}
                                                        onChange={(e) => formValueChange('step3', e.target.name, e.target.value)}
                                                    >
                                                        <option value="">-- Select Product --</option>
                                                        <option value="Shares, Bonds, Equities, ETFs">Shares, Bonds, Equities, ETFs</option>
                                                        <option value="Derivatives (Future, Options, Swaps)">Derivatives (Future, Options, Swaps)</option>
                                                        <option value="Forex or Rolling Spot (FX)">Forex or Rolling Spot (FX)</option>
                                                        <option value="CFDs">CFDs</option>
                                                    </Input>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <Label>Frequency</Label>
                                                <div className="input-group mb-3">
                                                    <Input className={"form-control " + ((!validationErrors.step3.cfdFrequency || formData.step3.cfdFrequency) ? 'is-valid' : 'is-invalid')}
                                                        name="cfdFrequency"
                                                        type="select"
                                                        value={formData.step3.cfdFrequency}
                                                        required=""
                                                        onChange={(e) => formValueChange('step3', e.target.name, e.target.value)}
                                                    >
                                                        <option value="">-- Select Product --</option>
                                                        <option value="Rarely: 1 to 25 Trades A Year">Rarely: 1 to 25 Trades A Year</option>
                                                        <option value="Sometime: 25 to 100 Trades A Year">Sometime: 25 to 100 Trades A Year</option>
                                                        <option value="Often: Over a 100 Trades A Year">Often: Over a 100 Trades A Year</option>
                                                    </Input>
                                                </div>
                                            </Col>
                                        </>
                                    )}
                                    {formData.step3.experiencedTrading === false && (
                                        <Col md={12}>
                                            <div className="form-check">
                                                <Input className="form-check-input"
                                                    type="checkbox"
                                                    name="learn"
                                                    id="wantToLearn"
                                                    checked={!formData.step3.experiencedTrading ? formData.step3.learn : false}
                                                    onChange={(e) => formValueChange('step3', e.target.name, e.target.checked)} />
                                                <Label className="form-check-label" htmlFor="wantToLearn">
                                                    I want to learn, and I have understood all risks associated with this trading platform.
                                                </Label>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Label>Will this account be traded by anyone other than you?</Label>
                                        <div className="input-group mb-3">
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="accountTradedByOthers"
                                                    id="accountTradedByOthersYes"
                                                    value="Yes"
                                                    checked={formData.step3.accountTradedByOthers === true}
                                                    onChange={(e) => formValueChange('step3', e.target.name, true)} />
                                                <Label className="form-check-label" htmlFor="accountTradedByOthersYes">Yes</Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="accountTradedByOthers"
                                                    id="accountTradedByOthersNo"
                                                    value="No"
                                                    checked={formData.step3.accountTradedByOthers === false}
                                                    onChange={(e) => formValueChange('step3', e.target.name, false)} />
                                                <Label className="form-check-label" htmlFor="accountTradedByOthersNo">No</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    {formData.step3.accountTradedByOthers === true && (
                                        <Col md={4}>
                                            <Label>Please attach Power of Attorney ( <a href="https://www.godocm.com/limited-power-of-attorney-account-manager/" target="_blank" rel="noopener noreferrer">Link</a>) </Label>
                                            <div className="input-group mb-3">
                                                <Input className={"form-control-file " + ((!validationErrors.step3.powerOfAttorney || formData.step3.powerOfAttorney) ? 'is-valid' : 'is-invalid')}
                                                    name="powerOfAttorney"
                                                    type="file"
                                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                                    onChange={(e) => {
                                                        const maxFileSize = 5 * 1024 * 1024; // 2 MB in bytes

                                                        const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                                        const fileName = e.target.value;
                                                        const file = e.target.files[0];
                                                        const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                                        if (file) {
                                                            if (file.size > maxFileSize) {
                                                                alert('File size exceeds the limit of 5 MB.');
                                                                e.target.value = '';
                                                                formValueChange('step3', e.target.name, e.target.value);
                                                            } else if (fileExtension.includes(fileExtensionCheck)) {
                                                                setFileData(e.target.value);
                                                                updateListFunction('POWER_OF_ATTORNEY', [e.target.files[0]]);
                                                                formValueChange('step3', e.target.name, e.target.value);
                                                            } else {
                                                                alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                                e.target.value = '';
                                                                formValueChange('step3', e.target.name, e.target.value);
                                                            }
                                                        }
                                                    }
                                                    }
                                                />
                                            </div>
                                            {formData.step3.powerOfAttorney && <span className="fs-6">SelectedFile: {formData.step3.powerOfAttorney.slice(12, 24)}</span>}
                                        </Col>
                                    )}
                                </Row>
                                <Row>
                                    <Col md={4} className="d-flex align-items-center justify-content-start">
                                        <div className="input-group mb-3 w-auto">
                                            <div className="form-check form-check-inline mt-2 me-1">
                                                <Input className="form-check-input"
                                                    type="checkbox"
                                                    name="acceptTradingTerms"
                                                    id="acceptTradingTermsYes"
                                                    value="Yes"
                                                    checked={formData.step3.acceptTradingTerms === true}
                                                    onChange={(e) => formValueChange('step3', e.target.name, true)} />
                                                {/* <Label className="form-check-label" htmlFor="acceptTradingTermsYes">Yes</Label> */}
                                            </div>
                                        </div>
                                        <Label>I accept electronic trading terms</Label>
                                    </Col>
                                </Row>
                            </div>
                        }
                        {currentStep === 3 && (
                            <div className="justify w_100 mt-4">
                                <Row>
                                    <Col md={4}>
                                        <Label><h6>FATCA:</h6> Are you a US Citizen and subject to tax?</Label>
                                        <div className="input-group mb-3">
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="usCitizen"
                                                    id="usCitizenYes"
                                                    value="Yes"
                                                    checked={formData.step4.usCitizen === true}
                                                    onChange={(e) => formValueChange('step4', e.target.name, true)} />
                                                <Label className="form-check-label" htmlFor="usCitizenYes">Yes</Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="usCitizen"
                                                    id="usCitizenNo"
                                                    value="No"
                                                    checked={formData.step4.usCitizen === false}
                                                    onChange={(e) => formValueChange('step4', e.target.name, false)} />
                                                <Label className="form-check-label" htmlFor="usCitizenNo">No</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    {formData.step4.usCitizen === true && (
                                        <Col md={4}>
                                            <Label>TIN</Label>
                                            <div className="input-group mb-3">
                                                <Input
                                                    className={"form-control " + ((!validationErrors.step2.taxIdentificationNumber || formData.step2.taxIdentificationNumber) ? 'is-valid' : 'is-invalid')}
                                                    name="taxIdentificationNumber"
                                                    type="text"
                                                    value={formData.step2.taxIdentificationNumber}
                                                    required=""
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) { // Only allow numbers
                                                            formValueChange('step2', e.target.name, value);
                                                        }
                                                    }}
                                                    placeholder="Enter TIN"
                                                />
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Label><h6>Politically Exposed Person (PEP):
                                            <div className="tooltip" style={{ opacity: '1', position: 'relative', top: '-3px' }}><PiExclamationMarkDuotone style={{ rotate: '180deg' }} />
                                                <span className="tooltiptextPEP">
                                                    PEP are Natural persons who are or have been entrusted with prominent public functions in the State or any other foreign country such as Heads of States or Governments, senior politicians, senior government officials, judicial or military officials, senior executive managers of state-owned corporations, and senior officials of political parties and persons who are, or have previously been, entrusted with the management of an international organisation or any prominent function within such an organisation; and also includes:
                                                     Direct family members (of the PEP, who are spouses, children, spouses of children, parents).
                                                     Associates known to be close to the PEP, which include:
                                                    ‒ Individuals having joint ownership rights in a legal person or arrangement or any other close Business Relationship with the PEP.
                                                    ‒ Individuals having individual ownership rights in a legal person or arrangement established in favour of the PEP.
                                                </span>
                                            </div>
                                        </h6>
                                            Are you a PEP?
                                        </Label>
                                        <div className="input-group mb-3">
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="politicallyExposed"
                                                    id="pepYes"
                                                    value="Yes"
                                                    checked={formData.step4.politicallyExposed === true}
                                                    onChange={(e) => { formValueChange('step4', e.target.name, true); formValueChange('step4', 'beneficialOwnerCode', 'Y') }} />
                                                <Label className="form-check-label" htmlFor="pepYes">Yes</Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="politicallyExposed"
                                                    id="pepNo"
                                                    value="No"
                                                    checked={formData.step4.politicallyExposed === false}
                                                    onChange={(e) => { formValueChange('step4', e.target.name, false); formValueChange('step4', 'beneficialOwnerCode', 'N') }} />
                                                <Label className="form-check-label" htmlFor="pepNo">No</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <h6>
                                            Declaration:
                                        </h6>
                                        <p>
                                            Prior to submitting this application form, it is essential that you have read and understood the following terms and conditions that will govern your trading account with GODO LTD (“GoDo”), and which together with this application form, comprise our ‘Client Agreement’ with you.</p>
                                        <Col md={12}>
                                            <div className="input-group mb-3">
                                                <label className="d-flex align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        name="declaration"
                                                        checked={true}
                                                        onChange={(e) => {
                                                            formValueChange('step4', e.target.name, true)
                                                            //setDeclaration(!declaration)
                                                        }}
                                                    />
                                                    &nbsp;&nbsp;&nbsp; I hereby declare the following:
                                                </label>
                                            </div>
                                        </Col>
                                        <ul>
                                            <li className="linkList">
                                                <p style={{ margin: "0px" }}>I agree that I have access to all information and duly understood:
                                                </p>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Website-Terms-Conditions.pdf" target="_blank" rel="noopener noreferrer"  >Terms & Conditions;</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Client-Complaint-Policy.pdf" target="_blank" rel="noopener noreferrer" >Complaint Policy;</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Conflicts-of-Interest-Policy.pdf" target="_blank" rel="noopener noreferrer" >Conflicts Of Interest Policy;</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Cookies-Policy-form.pdf" target="_blank" rel="noopener noreferrer" >Cookies Policy;</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Credit-Card-Disclaimer.pdf" target="_blank" rel="noopener noreferrer" >Credit Card Disclaimer;</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Execution-Policy.pdf" target="_blank" rel="noopener noreferrer" >Execution Policy;</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/04/Privacy-Policy.pdf" target="_blank" rel="noopener noreferrer" >Privacy Policy;</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Withdrawal-and-refund-policy-.pdf" target="_blank" rel="noopener noreferrer"  >Withdrawal & Refund;</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Risk-Warning.pdf" target="_blank" rel="noopener noreferrer" >Risk Warning</a>
                                            </li>
                                            <li>I understand the risk of margin trading and aware that a significant loss greater than the initially deposit account can be incurred</li>
                                            <li>I confirm that all the information provided is correct and in case of any change I am responsible to notify GoDo in written.</li>
                                        </ul>
                                        <Col md={12}>
                                            <div className="input-group mb-3">
                                                <label className="d-flex align-items-start justify-content-start">
                                                    <input
                                                        type="checkbox"
                                                        name="agreement"
                                                        style={{ marginTop: '7px' }}
                                                        checked={true}
                                                    />
                                                    <p className="mb-0 ms-3">
                                                        {
                                                            entity === 'FSA' ? "I hereby signed my agreement with GODO LTD and agree that my trading account is subject to the regulations under Financial Services Authority (FSA), Saint Vincent." :
                                                                entity === 'SCA' ? "I hereby signed my agreement with GODO LTD and agree that my trading account is subject to the regulations under Securities Commodities Authority (SCA), UAE." :
                                                                    "I hereby signed my agreement with GODO LTD and agree that my trading account is subject to the regulations under Financial Services Commission (FSC), Mauritius."

                                                        }
                                                    </p>
                                                </label>
                                            </div>
                                        </Col>
                                    </Col>
                                </Row>
                            </div>
                        )
                        }
                        <div className="justify align-items-center d-flex flex-column flex-sm-row gap-y-2 mt-3 ">
                            {/* {currentStep === 1 ? '' : <Button onClick={(e) => submitForm(e, 'previous')} className='primary-bg-color col buttonWidth mobile-disabled' style={{ height: '50px' }}>
                                Previous
                            </Button>} */}
                            <Button type="submit" onClick={(e) => submitForm(e)} className='primary-bg-color col buttonWidth' style={{ height: '50px', background: '#26539F' }}>
                                {t('Submit')}
                            </Button>
                        </div>
                        <div className="mt-3">
                            <Row md={12}>
                                <Col>
                                    <p className="text-danger">
                                        <Trans i18nKey="CautionMessage">
                                            <b>Exercise caution:</b> If uncertain about trading risks or uncomfortable with leveraging, it's advisable not to proceed with the application form, as trading entails the potential loss of your entire investment.
                                        </Trans>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </CustomForm>
                </>}
        </div>
    )
}
